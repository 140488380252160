<template>
    <page-header-wrapper>
        <template slot="extra">
            <a-button type="primary" @click="add" v-action:add>新增</a-button>
        </template>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper" v-action:query>
                <a-form layout="inline">
                    <a-row :gutter="24">
                        <a-col :md="8" :sm="24">
                            <a-form-item label="时间筛选：">
                                <a-range-picker @change="getDate" v-model="queryParam.time" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-form-item label="分类：">
                                <a-select v-model="queryParam.type" style="width: 150px" @change="getCate">
                                    <a-select-option :value="item.id" v-for="(item,index) in category" :key="item.id">
                                        {{item.title}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-form-item label="关键词：">
                                <a-input style="width: 80%" placeholder="请输入" v-model="queryParam.key" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="4 || 24" :sm="24">
                            <span class="table-page-search-submitButtons">
                                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>

            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto" :pageNum="pageNum">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
                <span slot="type" slot-scope="type">
                    {{type | typeFilter}}
                </span>
                <span slot="time" slot-scope="create_time">
                    {{create_time | time}}
                </span>
                <span slot="operation">
                    --
                </span>
                <span slot="action" slot-scope="text, record">
                    <template>
                        <a @click="handleEdit(record)" v-action:edit>编辑</a>
                        <a-divider type="vertical" />
                        <a @click="handleDel(record)" v-action:del>删除</a>
                    </template>
                </span>
            </s-table>

        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue'
	import { getUrlKey } from '@/utils'
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
        getWqrList,
        getWqrType,
        delWqrType,
        getWxInfo
    } from '@/api/article'

    const columns = [
        {
            title: '序号',
            scopedSlots: {
                customRender: 'serial'
            },
            align:'center',
            width:'90px'
        },
        {
            title: '分类',
            dataIndex: 'type',
            scopedSlots: {
                customRender: 'type'
            },
            align:'center',
			width:'180px'
        },
        {
            title: '文章标题',
            dataIndex: 'title',
            align:'center',
			width:'200px'
        },
        {
            title: '操作人',
            dataIndex: '',
            align:'center',
            scopedSlots: {
                customRender: 'operation'
            },
			width:'90px'
        },
        {
            title: '发布时间',
            dataIndex: 'create_time',
            scopedSlots: {
                customRender: 'time'
            },
            align:'center',
			width:'180px'
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: {
                customRender: 'action'
            },
            align:'center'
        }
    ]

        
    let that = this
    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                visible: false,
                advanced: false,
                // 查询参数
                queryParam: {
                    time: [],
                    key: '',
                    startTime: '',
                    endTime: '',
                    type:'0'
                },
                // 加载数据方法 必须为 Promise 对象
                loadData: parameter => {
                    //console.log('parameter',parameter)
                    const requestParameters = Object.assign({}, parameter, this.queryParam)
                    //console.log('requestParameters',requestParameters)
					if(requestParameters.type==0){
						requestParameters.type=''
					}
                    return getWqrList(requestParameters).then(res => {
                        return res.data
                    })
                },
                category:[],    //分类
				pageNum:3,
            }
        },
        beforeCreate: function () {
            that = this;
        },
        created(){
            this.getCateList();
			let current = getUrlKey('p')||0
			console.log('current',current)
			this.pageNum = Number(current)
        },
        methods: {
            getCateList(){   //获取分类
                let params = {}
                getWqrType(params).then(res =>{
                    if(res.code==200){
                        this.category = [{id:'0',title:'全部'}].concat(res.msg);
                    }
                })
            },
            getCate(e){   //获取分类
                //console.log(e)
                this.queryParam.type = e==0?'':e;
                //this.$refs.table.refresh(true)
            },
            add(){   //新增
                this.$router.push({path:'newsDetail?id='})
            },
            getDate(date, dateString) { //时间筛选
                this.queryParam.time = dateString
                this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0]+' 00:00:00': ''
                this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1]+' 23:59:59' : ''
            },
            handleEdit(data){  //编辑
                this.$router.push({path:'newsDetail?id='+data.id})
            },
            handleDel(data){   //删除
                let that = this
                Modal.confirm({
                    content:'确认删除吗?',
                    onOk(){
                        let params = {
                            id:data.id
                        }
                        delWqrType(params).then(res =>{
                            //console.log('res',res);
                            if(res.code==200){
                                that.$message.success('删除成功')
                                that.$refs.table.refresh(true)
                            }
                        })
                    },
                    onCancel(){
                        
                    }
                })
            },
            reset() { //重置
                this.queryParam.time = null
				this.queryParam.startTime = ''
				this.queryParam.endTime = ''
                this.queryParam.key = ''
                this.queryParam.type = '0'
                this.$refs.table.refresh(true)
            },
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            },
            typeFilter(type) {
                for(let i in that.category){
                    if(that.category[i].id == type){
                        return that.category[i].title
                    }
                }
                return '-'
            }
        }
    }
</script>


<style scoped>
.deliv_item{display:flex;align-items: center;margin-top: 36px;}
.deliv_item_label{width: 120px;}
.deliv_item_label i{font-style: normal;color: #FF3B30;}
.modal_item{display: flex;margin-top: 10px;}
.modal_item div:nth-child(1){min-width: 112px;}
.modal_item div:nth-child(2) img{margin-top: 5px;}
</style>